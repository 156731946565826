import { Component, Watch } from 'vue-property-decorator';
import { AxiosError, AxiosResponse } from 'axios';
import { TreeViewColumn, TreeViewOptions } from '@/components/tree-view/TreeView';
import ErrorHandler from '@/support/ErrorHandler';
import { Organization } from '@/models/Organization';
import { ReportTypeObject } from '@/views/ReportStats/ReportStats';
import { Rpc } from '@/models/Rpc';
import { Department } from '@/models/Department';
import { cloneDeep } from 'lodash';
import { MoreInfo } from '@/components/dialog/more-info-dialog/MoreInfoDialog';
import AbstractMoreInfoDialog from '@/components/dialog/more-info-dialog/AbstractMoreInfoDialog';
import { OuderdomsanalyseInfo } from '@/support/Info';
import { YearFilters } from '@/support/years';

@Component<OuderdomsAnalyse>({})
export default class OuderdomsAnalyse extends AbstractMoreInfoDialog {
  public $pageTitle = 'Ouderdoms analyse';

  // report types
  protected reportTypes: ReportTypeObject[] = [];

  protected moreInfo: MoreInfo[] = OuderdomsanalyseInfo;

  // Organizations
  protected organizations: Organization[] | null = null;

  protected selectedOrganization: Organization | null = null;

  protected activeOrganization = '';

  // departments
  protected departments: Department[] = [];

  protected years: string[] = YearFilters;

  protected filters: OuderdomsAnalyseFilters = {
    experts: [],
    secondExperts: [],
    departments: [],
    hideAfterToday: false,
    year: '2021',
  };

  protected savedFilters: {[id: string]: OuderdomsAnalyseFilters} = {};

  // loaders
  protected isLoading = false;

  protected items: RealtimeManagementStruct[] = [];

  protected columns: TreeViewColumn[] = [
    {
      key: 'first',
      name: 'Naam',
      style: 'min-width: 200px !important',
      visible: true,
    },
    {
      name: 'Jan',
      key: 'year',
      class: 'text-xs-right',
      style: 'min-width: 90px !important; border-left: 1px solid #cccccc;',
      visible: true,
    },
    {
      name: 'Feb',
      key: 'year',
      class: 'text-xs-right',
      style: 'min-width: 90px !important; border-left: 1px solid #cccccc;',
      visible: true,
    },
    {
      name: 'Mrt',
      key: 'year',
      class: 'text-xs-right',
      style: 'min-width: 90px !important; border-left: 1px solid #cccccc;',
      visible: true,
    },
    {
      name: 'Apr',
      key: 'year',
      class: 'text-xs-right',
      style: 'min-width: 90px !important; border-left: 1px solid #cccccc;',
      visible: true,
    },
    {
      name: 'Mei',
      key: 'year',
      class: 'text-xs-right',
      style: 'min-width: 90px !important; border-left: 1px solid #cccccc;',
      visible: true,
    },
    {
      name: 'Jun',
      key: 'year',
      class: 'text-xs-right',
      style: 'min-width: 90px !important; border-left: 1px solid #cccccc;',
      visible: true,
    },
    {
      name: 'Jul',
      key: 'year',
      class: 'text-xs-right',
      style: 'min-width: 90px !important; border-left: 1px solid #cccccc;',
      visible: true,
    },
    {
      name: 'Aug',
      key: 'year',
      class: 'text-xs-right',
      style: 'min-width: 90px !important; border-left: 1px solid #cccccc;',
      visible: true,
    },
    {
      name: 'Sept',
      key: 'year',
      class: 'text-xs-right',
      style: 'min-width: 90px !important; border-left: 1px solid #cccccc;',
      visible: true,
    },
    {
      name: 'Okt',
      key: 'year',
      class: 'text-xs-right',
      style: 'min-width: 90px !important; border-left: 1px solid #cccccc;',
      visible: true,
    },
    {
      name: 'Nov',
      key: 'year',
      class: 'text-xs-right',
      style: 'min-width: 90px !important; border-left: 1px solid #cccccc;',
      visible: true,
    },
    {
      name: 'Dec',
      key: 'year',
      class: 'text-xs-right',
      style: 'min-width: 90px !important; border-left: 1px solid #cccccc;',
      visible: true,
    },
    {
      key: 'report_count',
      name: 'Totaal',
      class: 'text-xs-right',
      style: 'min-width: 120px !important border-left: 1px solid #cccccc;',
      visible: true,
    },
  ];

  protected treeViewOptions: TreeViewOptions = {
    showStatusLabels: true,
  };

  public mounted() {
    this.initialize();
  }

  protected async initialize() {
    this.emitBreadcrumb();

    await this.getOrganizations();
  }

  protected async getOrganizations() {
    if (this.$store.state.isServiceOrganization) {
      this.organizations = await new Organization()
        .all()
        .catch((error: AxiosError) => {
          ErrorHandler.network(error);
        });
      if (this.organizations && ! this.activeOrganization) {
        this.activeOrganization = this.organizations[0].id || '';
        this.organizations.forEach((organization) => {
          if (organization?.id) {
            this.savedFilters[organization.id] = cloneDeep(this.filters);
          }
        });
      }
    } else {
      this.activeOrganization = this.$store.state.Auth.organization.id;
      this.organizations = [cloneDeep(this.$store.state.Auth.organization)];
      this.savedFilters[this.activeOrganization] = cloneDeep(this.filters);
    }
  }

  protected applyFilters(organizationId: string) {
    this.isLoading = true;
    const payload = {
      signature: 'management-reports:age-analysis',
      body: this.filters,
    };

    const rpcClient = new Rpc();
    if (this.$store.state.isServiceOrganization) {
      rpcClient.dmz(organizationId);
    }

    rpcClient
      .rpcPost(payload, false)
      .then((response: AxiosResponse) => {
        this.$set(this.items, `${[this.activeOrganization]}`, response.data);
        this.isLoading = false;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        this.isLoading = false;
      });
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Ouderdoms analyse' },
        ],
      });
  }

  protected activeOrganizationChanged(id: string) {
    this.filters = this.savedFilters[id];
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }

  @Watch('filters', { deep: true })
  protected filtersChanged() {
    this.savedFilters[this.activeOrganization] = this.filters;
  }
}

interface RealtimeManagementStruct {
  id?: string;
  active: boolean;
  name: string;
  data: RealtimeManagementData;
  children: RealtimeManagementStruct[];
}

interface RealtimeManagementData {
  planned_reports: string;
  delivered_reports: string;
  first_time_right: string;
  doorlooptijd: string;
  doorlooptijd_percentage: string;
  costs: string;
}

interface OuderdomsAnalyseFilters {
  experts: string[];
  secondExperts: string[];
  year: string;
  departments: string[];
  hideAfterToday: boolean;
}
